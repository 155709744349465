<template>
  <div>
    <div class="pc container-top">
      <div class="banner">
        <div class="banner-content">
          <div style="text-align: center">
            <div class="size-36 weight-700" style="line-height: 48px;margin-bottom: 12px">플랫폼 운영 솔루션 API 이용 가이드</div>
            <div class="body0-medium">플랫폼 운영에 필요한 관리자 사이트+백엔드</div>
          </div>
        </div>
      </div>
      <div class="container">
        <div v-for="(item,idx) in content" :key="'content-'+idx">
          <div class="subtitle3 main">{{ item.title }}</div>
          <template v-if="item.content">
            <div class="body2 sub2">{{ item.content }}</div>
            <img :src="item.img" :alt="item.title" style="margin-top:32px">
          </template>
          <template v-else>
            <div v-for="(step,idx) in item.step" :key="'step-'+idx" class="box-step">
              <div class="h7 primary">STEP {{ idx+1 }}</div>
              <div class="h6 main step-title">{{ step.title }}</div>
              <div class="body2 sub2">{{ step.content }}</div>
            </div>
          </template>
        </div>
        <div class="flex-center" style="margin-top:80px">
          <button class="button is-primary" @click="clickLink">견적신청 바로가기</button>
        </div>
      </div>
    </div>
    <div class="mobile container-top">
      <div class="banner">
        <div class="banner-content">
          <div style="text-align: center">
            <div class="size-22 weight-700" style="line-height: 28px;margin-bottom: 4px">플랫폼 운영 솔루션 API 이용 가이드</div>
            <div class="size-14">플랫폼 운영에 필요한 관리자 사이트+백엔드</div>
          </div>
        </div>
      </div>
      <div class="container">
        <div v-for="(item,idx) in content" :key="'content-'+idx">
          <div class="subtitle5 main">{{ item.title }}</div>
          <template v-if="item.content">
            <div class="body4 sub2">{{ item.content }}</div>
            <img :src="item.img" :alt="item.title" style="margin-top:18px">
          </template>
          <div v-else>
            <div v-for="(step,idx) in item.step" :key="'step-'+idx" class="box-step">
              <div class="h8 primary">STEP {{ idx+1 }}</div>
              <div class="body4-bold main step-title">{{ step.title }}</div>
              <div class="body5 sub2">{{ step.content }}</div>
            </div>
          </div>
        </div>
        <div class="flex-center" style="margin-top:80px">
          <button class="button is-primary body2-medium" @click="clickLink">견적신청 바로가기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ServiceInquiryGuide",
    created() {

    },
    data() {
      return {
        content: [
          {
            title: '플렛폼 운영 솔루션 API이란?',
            content: '프론트엔드 자체 제작을 원하는 클라이언트에게 런치팩의 관리자 사이트와 운영 솔루션을 제공하는 상품입니다. 런치팩의 관리자 사이트는 플랫폼 사업 운영에 반드시 필요한 기능들(상품/파트너 관리, 배송/주문 관리, 정산 등)을 포함합니다. 시장에서 성능 검증을 마친 안정성 있는 관리자 사이트로 플랫폼 개발 부담을 줄여보세요!',
            img: '/static/img/img_api.png',
          },
          {
            title: '계약진행절차',
            step: [
              {
                title: '이용 신청',
                content: "‘Solution API 이용 신청’ 페이지에서 이용 신청서를 작성합니다. 이후 상세한 서비스 파악을 위해  담당지와 유선 상담을 진행합니다."
              },
              {
                title: '계약 진행',
                content: '선택하신 상품의 조건과 이용 기간을 검토한 후 계약을 진행합니다. 관리자 사이트 API와 운영 솔루션의 이용 기간은 고객의 서비스 예상 이용 기간에 맞춰 조건 설정이 가능합니다.'
              },
              {
                title: '서비스 이용',
                content: '계약 진행 후 고객이 구매한 상품을 이용가이드와 함께 메일로 전달합니다. 호스팅, 운영 솔루션 이용료는 서비스 이용 시작일을 기준으로 매달 정기 결제 됩니다. '
              }
            ]
          }
        ]
      }
    },
    methods: {
      clickLink() {
        this.$router.push('/solution_api_reg');
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .banner
    background linear-gradient(132.62deg, #242428 34.49%, #4F4F4F 88.8%);
    height 320px
    position relative
    overflow hidden
    color white
  .banner-content
    position absolute
    width $page-width
    left 50%
    transform translateX(-50%)
    display flex
    align-items center
    justify-content center
    height 100%
    z-index 1

  .subtitle3
    margin 80px 0 16px 0
  .subtitle5
    margin 32px 0 8px 0
  .body2
  .body4
  .body5
    white-space pre-line
  .box-step
    border 1px solid $gray1
    border-radius 8px
    padding 40px
    margin-top 32px
  .box-step:first-child
    margin-top 16px

  .step-title
    padding 8px 0 16px 0
    border-bottom 1px solid $gray2
    margin-bottom 16px
  .button
    width 242px

  @media (max-width:1024px)
    .banner
      height 160px
      padding 28px 0
    .banner-content
      width 100%
      text-align center
      top 0
    .box-step
      padding 16px
    .step-title
      padding 8px 0
      margin-bottom 8px
    .button
      font-size 13px
      width 170px
      height 36px
</style>
